var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"fix",attrs:{"id":"header"}},[_c('div',{staticClass:"hd_inr cf"},[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":{ name: 'Home' }}},[_c('img',{attrs:{"src":"/media/img/logo.svg","alt":"어플레이즈"}})])],1),_c('div',{attrs:{"id":"nav"}},[_c('div',{staticClass:"gnb"},[_c('ul',{staticClass:"cf"},[_c('li',{staticClass:"gnb_join"},[_c('router-link',{attrs:{"to":{ name: 'Login' }}},[_vm._v("로그인/회원가입")])],1)])])])]),_c('div',{staticClass:"one_depth"},[_c('router-link',{staticClass:"policy",class:{ on : this.$route.path === '/stplat/service' },attrs:{"to":{
                    name: 'HomeService',
                }}},[_vm._v(" 서비스 이용약관 ")]),_c('router-link',{staticClass:"personal",class:{ on : this.$route.path === '/stplat/privacy' },attrs:{"to":{
                    name: 'HomePrivacy',
                }}},[_vm._v("개인정보 처리방침 ")])],1)]),_c('div',{staticClass:"main cs policy"},[_c('div',{staticClass:"main_box"},[_c('h1',[_vm._v("이용약관")]),_c('div',{staticClass:"cs_main"},[_c('div',{staticClass:"side_depth_box"},[_c('div',{staticClass:"one_depth"},[_c('router-link',{staticClass:"policy",class:{ on : this.$route.path === '/stplat/service' },attrs:{"to":{
                                name: 'HomeService',
                            }}},[_vm._v(" 서비스 이용약관 ")]),_c('router-link',{staticClass:"personal",class:{ on : this.$route.path === '/stplat/privacy' },attrs:{"to":{
                                name: 'HomePrivacy',
                            }}},[_vm._v("개인정보 처리방침 ")])],1)]),_c('router-view')],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }