<template>
    <div>
        <div id="header" class="fix">
            <div class="hd_inr cf">
                <div class="logo">
                    <router-link
                        :to="{ name: 'Home' }">
                        <img src="/media/img/logo.svg" alt="어플레이즈">
                        <!-- <img src="/media/img/logo_beta.svg" alt="어플레이즈"> -->
                    </router-link>
                </div>
                <div id="nav">
                    <div class="gnb">
                        <ul class="cf">
                            <li class="gnb_join"><router-link :to="{ name: 'Login' }">로그인/회원가입</router-link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="one_depth">
                <router-link
                    class="policy"
                    :to="{
                        name: 'HomeService',
                    }"
                    :class="{ on : this.$route.path === '/stplat/service' }"
                >
                    서비스 이용약관
                </router-link>
                <router-link
                    class="personal"
                    :to="{
                        name: 'HomePrivacy',
                    }"
                    :class="{ on : this.$route.path === '/stplat/privacy' }"
                >개인정보 처리방침
                </router-link>
            </div>
        </div>
        <div class="main cs policy">
            <div class="main_box">
                <h1>이용약관</h1>
                <div class="cs_main">
                    <div class="side_depth_box">
                        <div class="one_depth">
                            <router-link
                                class="policy"
                                :to="{
                                    name: 'HomeService',
                                }"
                                :class="{ on : this.$route.path === '/stplat/service' }"
                            >
                                서비스 이용약관
                            </router-link>
                            <router-link
                                class="personal"
                                :to="{
                                    name: 'HomePrivacy',
                                }"
                                :class="{ on : this.$route.path === '/stplat/privacy' }"
                            >개인정보 처리방침
                            </router-link>
                            <!-- <router-link
                class="marketing on"
                :to="{
                  name: 'HomeMarketing',
                }"
                >마케팅 정보 수신
              </router-link> -->
                        </div>
                    </div>
                    <router-view />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent } from "@vue/composition-api";
import router from "@/router";
import $ from 'jquery';

export default defineComponent({
  name: 'HomePolicy',
  methods: {
    linkLogin () {
      router.push({ name: "Login" });
    },
    linkSignUp () {
      router.push({ name: "SignUp" });
    }
  },
  mounted () {
    window.scrollTo(0, 0);
    $(window).resize(function () {
      if (window.innerWidth <= 820) {
        $('.logo img').attr('src', '/media/img/logo_m.png');
      } else {
        $('.logo img').attr('src', '/media/img/logo_white.svg');
      }
    }).resize();
  }
});
</script>

<style scoped>
  #header.fix {
    background: #151515;
    -webkit-backdrop-filter: blur(100px);
    -o-backdrop-filter: blur(100px);
    -ms-backdrop-filter: blur(100px);
    -moz-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
  }
  #header.fix .one_depth {
    display: none;
  }

  @media all and (max-width: 500px) {
   #header.fix .one_depth {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-x: auto;
      margin:0 auto;
      padding:16px 5% 0;
      text-decoration: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.16);
      background: #151515;
  }
   #header.fix .one_depth a {
      position: relative;
      display: flex;
      justify-content: center;
      align-items:center;
      width: 50%;
      height:32px;
      font-size: 16px;
      color:rgb(225 225 225 / 60%);
      border:2px solid #151515;
      padding-bottom: 6px;
      margin-bottom: 0;
  }
   #header.fix .one_depth a.on {
      color: #fff;
      height: 32px;
      font-size: 16px;
      font-weight: 500;
      border-bottom: 2px solid #ff2d55;
  }
   #header.fix .one_depth a span {
      opacity: 0;
      display: block;
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 100%;
      height: 2px;
      border-radius: 5px;
      background: #Fff;
  }
   #header.fix .one_depth a span.on {
      opacity: 1;
  }
}

</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/font.css"></style>
<style src="@/assets/css/style.css"></style>
